.CattleCalculator-inputBlock {
    display: flex;
    flex-direction: column;
}

.CattleCalculator-inputRow {
    display: flex;
    flex-direction: row;
}

.CattleCalculator-inputRow div {
    flex-grow: 1;
    flex-basis: 0;
}

.CattleCalculator-inputLabel {
    text-align: left;
    line-height: 40px;
}
