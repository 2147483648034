.GrainCalculator-matif {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GrainCalculator-matifRow {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.GrainCalculator-matifCell {
    flex-grow: 1;
    line-height: 48px;
    text-align: center;
    width: 50%;
}

.GrainCalculator-deduction {
    color: red;
}
