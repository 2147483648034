.FarmersCard {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.FarmersCard-main {
    display: flex;
    flex-direction: row;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    cursor: pointer;
}

.FarmersCard-mainName {
    flex-grow: 1;
    text-align: left;
}

.FarmersCard-expand {
    height: 0;
    transition: 0.5s;
    text-align: left;
    color: #666;
    overflow-y: hidden;
}

.FarmersCard-expand--open {
    height: auto;
}

.FarmersCardsLine {
    display: flex;
    flex-direction: row;
    line-height: 25px;
    margin-bottom: 5px;
}

.FarmersCardsLine-icon {
    margin-right: 15px;
}

.FarmersCard-button {
    margin: 15px 0;
}
