.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-container {
  overflow-y: scroll;
  height: inherit;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

.App-content {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 1024px) {
  .App {
    width: auto;
    margin-left: 320px;
  }
}
