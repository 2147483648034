.MainMenu-container {
    margin-top: 20px;
}

.MenuItem {
    text-decoration: none;
}

.MenuItem-label {
    width: 100%;
    background-color: #fafafa;
    height: 65px;
    line-height: 65px;
    margin-bottom: 0;
    padding-bottom: 0;
    border: 1px solid rgba(0,0,0,.1);
    margin-top: -1px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #1f1f1f;
    text-align: center;
}

.MenuScreen-image {
    display: none !important;
}

@media screen and (min-width: 1024px) {
    .MenuScreen-container {
        display: none;
    }

    .FileTypes .MenuScreen-container {
        display: block;
    }

    .MenuScreen-image {
        display: block !important;
        width: 100%;
        margin-top: -20px;
    }
    /*.MenuScreen-root {*/
        /*background-size:     cover;                      !* <------ *!*/
        /*background: url(/assets/images/s1.jpg) no-repeat center center;*/
        /*height: 1000px;*/
    /*}*/
}
