.CattleList-descriptions {
    font-size: 14px;
    line-height: 30px;
}

.CattleList-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.CattleList-item {
    flex: 0 47%;
    text-align: left;
    margin-bottom: 10px;
}

.CattleList-itemImage {
    width: 100%;
}
