html, body {
  height: 100%;
  margin: 0;
  font-family: Roboto,sans-serif;
  box-sizing: border-box;
}

#root {
  height: 100%;
  background: url(/assets/background.jpg) center center;
}

@media screen and (min-width: 1024px) {
  #root {
    background: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  color: #1f1f1f;
  margin-bottom: 10px;
}

h5 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.button {
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .6px;
  display: inline-block;
  padding: 8px 18px;
  margin-bottom: 15px;
  text-decoration: none;
  border-width: 0;
  font-family: Roboto,sans-serif;
}

.button-success {
  background-color: #79a22f;
  color: #fff;
}

.button-success:disabled {
  background-color: #a2b384;
}

.button-warning {
  background-color: #f1c40f;
  color: #1f1f1f;
}

.select {
  width: 100%;
  height: 40px;
  border: solid 1px #cacaca;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  transition: all 250ms ease;
  color: #7f8c8d;
  font-size: 16px;
}

.text-field {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #7f8c8d;
  border: 1px solid #cacaca;
  -webkit-transition: 250ms;
  transition: 250ms;
  box-sizing: border-box;
}

.text-field[disabled] {
  background-color: #e9e9e9;
}

/*--------------------*/
/*---Table Settings---*/
/*--------------------*/

.table{
  width:100%;
  margin-bottom:35px;
}

.table-title{
  font-family:'Dosis', sans-serif;
  font-size:12px;
}

.table-sub-title{
  font-family:'Dosis', sans-serif;
  font-weight:bold;
  font-size:12px;
}

.price{
  font-size:12px;
}

.small-price{
  position:absolute;
  margin-left:0px;
  font-size:10px;
  margin-top:-3px!important;
}

.table {
  font-size:12px;
  text-shadow: 1px 1px 0px #fff;
  background:#eaebec;
  border:#ccc 1px solid;
  margin-bottom:25px;
}

.table th {
  padding:21px 25px 22px 25px;
  border-bottom:1px solid #e0e0e0;
}

.table th:first-child{
  text-align: center;
  padding-left:20px;
}

.table tr{
  text-align: center;
  padding-left:20px;
}

.table tr td:first-child{
  text-align: left;
  padding-left:3px;
  border-left: 0;
}

.table tr td {
  padding:3px;
  border-top: 1px solid #ffffff;
  border-bottom:1px solid #e0e0e0;
  border-left: 0px solid #e0e0e0;
}

.table tr.even td{
  background: #efefef;
}

.table tr:last-child td{
  border-bottom:0;
}

.table tr:hover td{
  background: #f8f8f8;
}

.table-heading{
  padding-top:10px;
  padding-bottom:15px;
}

.table-text{
  color:#6f6f6f;
}

.table-summaryRow {
  background-color: #DBDBDB;
  font-weight: bold;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  background-color: #f6f6f6;
  border-left: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  width: 100%;
  clear: both;
  margin-bottom: 27px;
}

thead {}

th {
  vertical-align: middle;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  font-weight: bold;
  color: #555;
  background-color: #f6f6f6;
}

tr {
  line-height: 18px;
}

td {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
  color: #666;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 18px;
  vertical-align: middle;
  background-color: #fdfdfd;
}

tr:hover > td {
  background: #fff;
}

.table-removeIconColumn {
  width:30px
}

.table-input {
  width: 45px;
  font-size: 14px;
  border: solid 1px #cacaca;
}

.block {
  display: block;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-left: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* ----- */

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-left: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/*------------------*/
/*------------------*/
/*----TaskLists----*/
/*------------------*/
/*------------------*/

.tasklist-container {
  display: flex;
  flex-direction: column;
}

.tasklist-item{
  height: 65px;
  line-height: 65px;
  margin-bottom: -1px;
  padding-bottom: 0px;
  border: solid 1px rgba(0,0,0,0.1);
  border-left: solid 4px #ecf0f1;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  background-color: #fafafa;
  transition: all 200ms ease;
  display: flex;
  flex-direction: row;
}

.tasklist-item i:first-child{
  height:65px;
  line-height:65px;
  width:40px;
  text-align:center;
  color:rgba(0,0,0,0.2);
  transition:all 200ms ease;
}

.tasklist-item h5{
  /*padding-left:50px;*/
  line-height:65px;
  font-size:14px;
  margin-top:0px;
  font-weight:500;
  transition:all 200ms ease;
  text-align:center;
  flex-grow: 1;
}

.tasklist-red{        border-left:solid 4px #c0392b;    }
.tasklist-green{      border-left:solid 4px #27ae60;    }
.tasklist-blue{       border-left:solid 4px #2980b9;    }
.tasklist-orange{     border-left:solid 4px #d35400;    }
.tasklist-yellow{     border-left:solid 4px #f1c40f;    }
.tasklist-gray{       border-left:solid 4px #666666;    }
.tasklist-dark{       border-left:solid 4px #1f1f1f;    }
.tasklist-magenta{    border-left:solid 4px #8e44ad;    }

.tasklist-completed{
  background-color:#fafafa;
  transition:all 200ms ease;
}

.tasklist-completed i:first-child{
  color:#27ae60!important;
  transition:all 200ms ease;
}

.tasklist-completed h5{
  color:rgba(0,0,0,0.5);
  transition:all 200ms ease;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}
