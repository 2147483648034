.Header {
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;
    border-bottom: solid 1px rgba(0,0,0,0.1);
    display: flex;
}

.Header-mainContainer {
    flex-grow: 1;
}

.Header-logo {
    width: 93px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    cursor: pointer;
}

.Header-logoImage {
    width: 93px;
    height: 36px;
}

.Header-leftButton, .Header-rightButton {
    width: 50px;
}

.Header-leftButton > .Header-button{
    padding-left: 10px;
    padding-top: 10px;

}

.Header-button {
    box-sizing: border-box;
    width: 50px;
    height: 60px;
    padding-left: 11px;
    padding-top: 13px;
    cursor: pointer;
    color: #799F3E;
}

#burger {
    width: 36px;
    height: 27px;
    top: 15px;
    right: 5px;
    display: block;
    z-index: 999999;
    /* margin-bottom: -45px; */
    position: absolute;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer; }

/* line 308, ../sass/main.scss */
#burger span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #799F3E;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }

/* line 327, ../sass/main.scss */
#burger span:nth-child(1) {
    top: 0px; }

/* line 331, ../sass/main.scss */
#burger span:nth-child(2) {
    top: 10px; }

/* line 335, ../sass/main.scss */
#burger span:nth-child(3) {
    top: 20px; }

/* line 339, ../sass/main.scss */
#burger.open span:nth-child(1) {
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }

/* line 347, ../sass/main.scss */
#burger.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }

/* line 352, ../sass/main.scss */
#burger.open span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg); }

