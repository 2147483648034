#Sidebar {
    height: 100%; /* 100% Full-height */
    width: 320px;
    max-width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: -437px;
    background-color: #FFFFFF;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    border-right: solid 1px rgba(0, 0, 0, 0.1);
}

#Sidebar.Sidebar-open {
    left: 0;
}

@media screen and (min-width: 1024px) {
    #Sidebar {
        left: 0;
    }
}

.Sidebar-closeButton {
    cursor: pointer;
    padding-left: 20px;
    color: #2980b9;
    font-weight: 700;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.Sidebar-logo {
    width: 93px;
    display: block;
    margin-right: auto;
    cursor: pointer;
}

.Sidebar-logoImage {
    width: 170px;
    height: 75px;
}

.SidebarMenuItem {
    text-decoration: none;
}

.SidebarMenuItem-subContainer {
    transition: all 0.3s ease;
    margin-left: 35px;
}

.SidebarMenuItem-label {
    height: 60px;
    line-height: 60px;
    font-family: Roboto,sans-serif;
    color: #3a3a3a;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    display: flex;
}

.SidebarMenuItem-text {
    flex-grow: 1;
}

.SidebarMenuItem-icon {
    color: #799F3E;
    text-align: center;
    margin: 5px 20px;
}

.SidebarMenuItem-iconPlus {
    margin-top: 0;
}

.SidebarMenuItem-icon.open {
    color: #CA1740;
}
