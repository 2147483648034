.FarmersListItem {
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 10px 0;
    margin-top: -1px;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-decoration: none;
}

.FarmersListItem-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #79a22f;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    margin-right: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.FarmersListItem-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 1;
    flex-shrink: 1;
}

.FarmersListItem-contentName {
    overflow: hidden;
    font-weight: 700;
    text-wrap: none;
    color: #1f1f1f;
}

.FarmersListItem-contentLocation {
    overflow-y: hidden;
    overflow-x: fragments;
    flex-grow: 1;
    font-size: 12px;
    color: #666;
    font-weight: 500;
    height: 25px;
}

.FarmersListItem-chevron {
    color: #1f1f1f;
    margin-top: 17px;
}
