/* line 1, ../sass/main.scss */
.home-mobile,
#footer-desctop {
  display: none; }

@media screen and (min-width: 1024px) {
  /* line 9, ../sass/main.scss */
  table tr td.min-width {
    width: 120px; }

  /* line 15, ../sass/main.scss */
  .cls {
    position: relative;
    clear: both; }

  /* line 19, ../sass/main.scss */
  .one-half-responsive {
    width: 100% !important;
    margin-left: 0 !important; }
    /* line 22, ../sass/main.scss */
    .one-half-responsive .half-width {
      width: 330px;
      margin: 0 auto; }

  /* line 27, ../sass/main.scss */
  .header-icon-left.mobile {
    display: none; }

  /* line 30, ../sass/main.scss */
  #burger {
    display: none !important; }

  /* line 33, ../sass/main.scss */
  .content {
    padding: 0 15px; }

  /* line 36, ../sass/main.scss */
  .store-product-heading {
    text-align: left;
    width: 763px;
    float: left;
    height: 206px;
    padding-left: 10px; }
    /* line 42, ../sass/main.scss */
    .store-product-heading h1, .store-product-heading h4 {
      text-align: left; }
    /* line 45, ../sass/main.scss */
    .store-product-heading .button-center {
      position: absolute;
      margin: 0 !important;
      bottom: 0;
      left: 15px; }

  /* line 52, ../sass/main.scss */
  .product-slider-container {
    display: block;
    width: 300px;
    margin: 0 auto;
    float: left; }

  /* line 58, ../sass/main.scss */
  .decoration-lines {
    display: none; }

  /* line 61, ../sass/main.scss */
  .store-item-list a u {
    top: 0px;
    display: block;
    margin-top: 0; }

  /* line 67, ../sass/main.scss */
  .store-item-list a strong {
    padding-left: 25px; }

  /* line 71, ../sass/main.scss */
  .store-item-list a em {
    padding-left: 25px; }

  /* line 74, ../sass/main.scss */
  #burger {
    display: none; }

  /* line 78, ../sass/main.scss */
  .sidebar-menu {
    padding-bottom: 10px !important;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1); }

  /* line 83, ../sass/main.scss */
  .snap-drawer .sidebar-footer {
    display: none; }

  /* line 87, ../sass/main.scss */
  #footer-desctop {
    z-index: 999;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding-top: 10px;
    font-size: 10px;
    padding-left: 17px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center; }
    /* line 102, ../sass/main.scss */
    #footer-desctop p {
      margin-bottom: 0px; }

  /* line 107, ../sass/main.scss */
  .home-mobile {
    display: block; }
    /* line 109, ../sass/main.scss */
    .home-mobile .bx-wrapper, .home-mobile .bx-viewport {
      height: 687px !important; }
    /* line 112, ../sass/main.scss */
    .home-mobile .bx-wrapper {
      margin-top: -80px;
      background: transparent;
      -webkit-box-shadow: none;
      box-shadow: none; }
    /* line 118, ../sass/main.scss */
    .home-mobile .bx-controls {
      margin-top: -55px; }

  /* line 123, ../sass/main.scss */
  .home-desctop {
    display: none; }

  /* line 131, ../sass/main.scss */
  #header-fixed {
    display: none !important; }

  /* line 134, ../sass/main.scss */
  .all-elements {
    position: relative;
    width: 1400px;
    height: 1400px;
    min-height: 100%;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    margin: 0 auto 0 auto; }

  /* line 152, ../sass/main.scss */
  .one-half-responsive.last-column {
    display: block;
    float: left;
    width: 31%;
    margin: 0 2%; }

  /* line 159, ../sass/main.scss */
  .one-third-responsive {
    width: 100%; }
    /* line 161, ../sass/main.scss */
    .one-third-responsive .user-list-item {
      width: 29%;
      float: left;
      text-align: left;
      margin: 25px 2%; }
    /* line 167, ../sass/main.scss */
    .one-third-responsive .decoration {
      display: none; }

  /* line 172, ../sass/main.scss */
  p.sidebar-divider,
  .close-sidebar {
    display: none !important; }

  /* line 178, ../sass/main.scss */
  .sidebar-light-clean div.submenu.submenu-active {
    margin-top: -25px; }
  /* line 181, ../sass/main.scss */
  .sidebar-light-clean .submenu-item {
    height: 40px; }
    /* line 183, ../sass/main.scss */
    .sidebar-light-clean .submenu-item em {
      color: #3a3a3a; }
      /* line 185, ../sass/main.scss */
      .sidebar-light-clean .submenu-item em:hover {
        color: #000 !important; }
    /* line 190, ../sass/main.scss */
    .sidebar-light-clean .submenu-item i {
      font-size: 17px !important;
      color: #799F3E !important;
      margin-top: -3px !important; }
    /* line 195, ../sass/main.scss */
    .sidebar-light-clean .submenu-item.submenu-active {
      color: #000 !important; }
      /* line 197, ../sass/main.scss */
      .sidebar-light-clean .submenu-item.submenu-active i {
        color: #CA1740 !important; }
  /* line 204, ../sass/main.scss */
  .sidebar-light-clean .menu-item {
    text-transform: uppercase; }
    /* line 206, ../sass/main.scss */
    .sidebar-light-clean .menu-item i:last-child {
      opacity: 1;
      font-size: 12px;
      top: 26px;
      left: 25px;
      color: #799F3E; }
    /* line 213, ../sass/main.scss */
    .sidebar-light-clean .menu-item i:first-child {
      position: absolute;
      font-size: 21px;
      width: 35px;
      height: 35px;
      border-radius: 35px;
      line-height: 35px;
      text-align: center;
      top: 50%;
      margin-top: -21px;
      margin-left: 20px;
      color: #799F3E; }
    /* line 227, ../sass/main.scss */
    .sidebar-light-clean .menu-item.submenu-active i:first-child {
      color: #CA1740; }
    /* line 230, ../sass/main.scss */
    .sidebar-light-clean .menu-item.submenu-active i:last-child {
      color: #CA1740; } }
/* line 241, ../sass/main.scss */
.sidebar-light-clean div.submenu.submenu-active {
  margin-top: -25px; }
/* line 244, ../sass/main.scss */
.sidebar-light-clean .submenu-item {
  height: 40px; }
  /* line 246, ../sass/main.scss */
  .sidebar-light-clean .submenu-item em {
    color: #3a3a3a; }
    /* line 248, ../sass/main.scss */
    .sidebar-light-clean .submenu-item em:hover {
      color: #000 !important; }
  /* line 253, ../sass/main.scss */
  .sidebar-light-clean .submenu-item i {
    font-size: 17px !important;
    color: #799F3E !important;
    margin-top: -3px !important; }
  /* line 258, ../sass/main.scss */
  .sidebar-light-clean .submenu-item.submenu-active {
    color: #000 !important; }
    /* line 260, ../sass/main.scss */
    .sidebar-light-clean .submenu-item.submenu-active i {
      color: #CA1740 !important; }
/* line 267, ../sass/main.scss */
.sidebar-light-clean .menu-item {
  text-transform: uppercase; }
  /* line 269, ../sass/main.scss */
  .sidebar-light-clean .menu-item i:last-child {
    opacity: 1;
    font-size: 12px;
    top: 26px;
    left: 14px;
    color: #799F3E; }
  /* line 276, ../sass/main.scss */
  .sidebar-light-clean .menu-item i:first-child {
    position: absolute;
    font-size: 21px;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    line-height: 35px;
    text-align: center;
    top: 50%;
    margin-top: -21px;
    margin-left: 14px;
    color: #799F3E; }
  /* line 290, ../sass/main.scss */
  .sidebar-light-clean .menu-item.submenu-active i:first-child {
    color: #CA1740; }
  /* line 293, ../sass/main.scss */
  .sidebar-light-clean .menu-item.submenu-active i:last-child {
    color: #CA1740; }

/* line 301, ../sass/main.scss */
* {
  margin: 0;
  padding: 0; }

/* burger */
/* line 308, ../sass/main.scss */
#burger {
  width: 36px;
  height: 27px;
  top: 15px;
  right: 5px;
  display: block;
  z-index: 999999;
  /* margin-bottom: -45px; */
  position: absolute;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }

/* line 329, ../sass/main.scss */
#burger span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #799F3E;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

/* line 348, ../sass/main.scss */
#burger span:nth-child(1) {
  top: 0px; }

/* line 352, ../sass/main.scss */
#burger span:nth-child(2) {
  top: 10px; }

/* line 356, ../sass/main.scss */
#burger span:nth-child(3) {
  top: 20px; }

/* line 360, ../sass/main.scss */
#burger.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

/* line 368, ../sass/main.scss */
#burger.open span:nth-child(2) {
  opacity: 0;
  left: -60px; }

/* line 373, ../sass/main.scss */
#burger.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg); }

@media screen and (max-width: 1024px) {
  /* line 382, ../sass/main.scss */
  .snap-drawer {
    width: 100%; }

  /* line 385, ../sass/main.scss */
  .news-strip a i {
    position: absolute;
    float: none;
    line-height: 50px;
    width: 40px;
    text-align: center; }

  /* line 392, ../sass/main.scss */
  .store-item-list a u {
    top: 0px !important;
    display: block;
    margin-top: 0 !important; }

  /* line 398, ../sass/main.scss */
  .store-item-list a strong {
    padding-left: 25px; }

  /* line 402, ../sass/main.scss */
  .store-item-list a em {
    padding-left: 25px; }

  /* line 409, ../sass/main.scss */
  .sidebar-light-clean .menu-item i:last-child {
    right: 30px;
    left: unset; } }
/* line 419, ../sass/main.scss */
.header-icon-left {
  font-size: 35px;
  color: #799F3E;
  display: block;
  position: absolute;
  top: 12px;
  left: 15px;
  z-index: 999999999999999; }
