.FertilizerCompare-table {
    /*font-size: 16px;*/
}

.FertilizerCompare-input {
    /*font-size: 16px;*/
    width: 60px;
    background-color: #ddd;
}

.FertilizerCompare-tableCell {
    height: 36px;
}

.FertilizerCompare-normColumn {
    width: 60px;
}
