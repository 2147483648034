.AapPhasesItem {
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 10px 0;
    /*margin-top: -1px;*/
    /*border-top: 1px solid rgba(0,0,0,.1);*/
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-decoration: none;
}

.AapPhasesItem-content {
    flex-grow: 1;
}

.AapPhasesItem-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #79a22f;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    margin-right: 20px;
}

.AapPhasesItem-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.AapPhasesItem-contentName {
    overflow: hidden;
    font-weight: 700;
    text-wrap: none;
    color: #1f1f1f;
}

.AapPhasesItem-contentLocation {
    overflow-y: hidden;
    flex-grow: 1;
    font-size: 12px;
    color: #666;
    font-weight: 500;
}

.AapPhasesItem-chevron {
    color: #1f1f1f;
    margin-top: 17px;
}

