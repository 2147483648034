.FileList-table {
    font-size: 16px;
    line-height: 20px;
}

tr.FileList-tableRow > td {
    padding: 5px;
    vertical-align: center;
    font-size: 16px;
    line-height: 20px;
}

tr.FileList-tableRow > td.FileList-iconColumn {
    padding: 5px;
}

.FileList-iconColumn {
    width: 30px;
}

.FileList-shareColumn {
    width: 40px;
    padding: 10px;
    font-size: 16px;
    line-height: 20px;
}

.FileList-shareRow {

}

.FileList-closeShare, .FileList-shareColumn {
    cursor: pointer;
}
