.FarmersDetails {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
}

.FarmersCulturesListItem {
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 10px 0;
    margin-top: -1px;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-decoration: none;
}

.FarmersCulturesListItem-content {
    flex-grow: 1;
}

.FarmersCulturesListItem-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    margin-right: 20px;
}

.FarmersCulturesListItem-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.FarmersCulturesListItem-contentName {
    overflow: hidden;
    font-weight: 700;
    text-wrap: none;
    color: #1f1f1f;
}

.FarmersCulturesListItem-contentArea {
    overflow-y: hidden;
    flex-grow: 1;
    font-size: 12px;
    color: #666;
    font-weight: 500;
}

.FarmersCulturesListItem-chevron {
    color: #1f1f1f;
    margin-top: 17px;
}

