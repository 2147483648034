.FarmersForm {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    color: #666;
}

.FarmersForm-row {
    display: flex;
    flex-direction: row;
}

.FarmersForm-row .text-field {
    width: 100%;
    box-sizing: border-box;
}

.FarmersForm-rowCell {
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;
}

.FarmersForm-rowCell:first-child {
    padding-right: 5px;
}

.FarmersForm-rowCell:last-child {
    padding-left: 5px;
}

.FarmersForm-submit {
    width: 100%;
}
