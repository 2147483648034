.Login {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.Login .cover-center {
  margin-left: -150px;
  margin-top: -118px;
}

.Login-overlay {
  height: 100%;
  width: 100%;
  opacity: 0.8;
  position: absolute;
  top: 0px;
  z-index: 1;
  background-color: #000000;
}

.Login-fieldContainer {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px rgba(255,255,255,0.1);
  margin-bottom: 20px;
  align-items: center;
}
/*.Login {*/
  /*position: absolute;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*width: auto;*/
  /*height: auto;*/
  /*z-index: 2;*/
  /*overflow: auto;*/
  /*-webkit-overflow-scrolling: touch;*/
  /*-webkit-transform: translate3d(0, 0, 0);*/
  /*-moz-transform: translate3d(0, 0, 0);*/
  /*-ms-transform: translate3d(0, 0, 0);*/
  /*-o-transform: translate3d(0, 0, 0);*/
  /*transform: translate3d(0, 0, 0);*/
/*}*/

/*.Login{*/
  /*margin-top:0px;*/
/*}*/

/*.Login-content{*/
  /*width:300px;*/
  /*z-index:10;*/
/*}*/

/*.Login .overlay{*/
  /*opacity:0.8;*/
  /*position:absolute;*/
  /*top:0px;*/
  /*z-index:1;*/
/*}*/

/*.Login input{*/
  /*transition:all 200ms ease;*/
/*}*/

/*.Login input:focus{*/
  /*border-bottom:solid 1px #27ae60!important;*/
  /*transition:all 200ms ease;*/
/*}*/

/*.Login-button{*/
  /*margin-top:25px;*/
/*}*/

/*!*Unboxed Layout*!*/

/*.Login .unboxed-layout .Login-field i{*/
  /*position:absolute;*/
  /*color:#FFFFFF;*/
  /*margin-top:12px;*/
  /*margin-left:10px;*/
/*}*/

/*.Login .unboxed-layout div input{*/
  /*display:block;*/
  /*width:100%;*/
  /*height:40px;*/
  /*line-height:40px;*/
  /*padding-left:40px;*/
  /*background-color:rgba(255,255,255,0);*/
  /*color:#FFFFFF;*/
  /*border-bottom:solid 1px rgba(255,255,255,0.1);*/
  /*margin-bottom:20px;*/
  /*font-size:12px;*/
/*}*/

/*.Login .unboxed-layout .decoration{*/
  /*background-color:rgba(255,255,255,0.1);*/
  /*margin-top:20px;*/
  /*margin-bottom:20px;*/
/*}*/

/*.Login .unboxed-layout .Login-logo{*/
  /*width:125px;*/
  /*height:100px;*/
  /*background-image:url(../images/pages-logo-light.png);*/
  /*background-size:125px 100px;*/
  /*margin-left:auto;*/
  /*margin-right:auto;*/
  /*margin-bottom:20px;*/
/*}*/

/*@media screen and ( max-height: 570px ){*/
  /*.Login-logo{*/
    /*display:none;*/
  /*}*/
  /*.Login-content .unboxed-layout{*/
    /*margin-top:-40px;*/
  /*}*/

  /*.Login-content .boxed-layout{*/
    /*padding-top:10px;*/
    /*padding-bottom:20px;*/
    /*margin-top:-30px;*/
  /*}*/

  /*.Login-content .boxed-layout a{*/
    /*height:40px;*/
    /*line-height:40px;*/
    /*padding-top:0px;*/
    /*padding-bottom:0px;*/
  /*}*/

  /*.Login-content .boxed-layout a i{*/
    /*line-height:40px;*/
    /*padding-top:0px;*/
    /*pading-bottom:0px;*/
    /*margin-top:-17px;*/
  /*}*/
/*}*/
