.SelectedFertilizer-inputField {
    min-width:65px;
    max-width: 100px;
    height:30px;
    margin: 5px 0
}

.SelectedFertilizers-input {
    font-size: 12px;
    width: 60px;
    background-color: #ddd;
    border: 0;
    text-align: right;
}
