.FarmersCultureFinancial {
    padding: 20px;
}

.FarmersCultureFinancial-input {
    border: solid 1px #cacaca;
    width: 75px;
    height: 20px;
    text-align: right;
}

.FarmersCultureFinancial-valueCell {
    width: 55px;
    text-align: right;
}

.FarmersCultureFinancial-inputSummaryContainer {
    display: flex;
}

.FarmersCultureFinancial-cultureSelected > td {
    background-color: #a6e346!important;
}

.FarmersCultureFinancial-inputSummary {
    flex-grow: 1;
    background-color: #e9e9e9;
    text-align: center;
    color: #000!important;
    font-size: 24px!important;
}

.FarmersCultureFinancial-deliveryContainer {
    display: flex;
    flex-direction: column;
}

.FarmersCultureFinancial-deliveryContainer select {
    border: solid 1px #cacaca;
}

.negativeDeduction {
    color: red;
}
