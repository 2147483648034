.CultureOverview {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
}

.CultureOverviewContent {
    background: rgba(255, 255, 255, .6);
    padding: 0 10px;
}

.CultureOverviewFertilizer > .table {
    font-size: 12px;
}

.CultureOverviewAap-block {
    border-bottom: solid 1px rgba(0,0,0,0.1);
}
