.ExpandableRow {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.ExpandableRow-mainRow {
    display: flex;
    flex-direction: row;
    font-size: 22px;
    cursor: pointer;
    padding: 20px 0;
}

.ExpandableRow-title {
    flex-grow: 1;
    text-align: left;
}

.ExpandableRow-expandIcon {
    margin-right: 15px;
}

.ExpandableRow-content{
    height: 0;
    transition: 0.5s;
    text-align: left;
    color: #666;
    overflow-y: hidden;
    font-size: 12px;
}

.ExpandableRow-content--open {
    height: auto;
}
