.AapType {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: left;
}

.AapType-nameTitle {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
}

.AapType-description {
    text-align: left;
}

.AapSummaryDate {
    font-size: 12px;
    text-shadow: 1px 1px 0 #fff;
    background: #eaebec;
    border: 1px solid #ccc;
    margin-bottom: 25px;
}

.AapSummaryDate-input {
    width: 93px;
    /*height: 45px;*/
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    color: #7f8c8d;
    border: 0;
    -webkit-transition: 250ms;
    transition: 250ms;
}

.AapSummaryDate-inputCell {
    width: 110px;
    padding: 0;
}

.AapSummaryDate-title {
    background-color: #f6f6f6;
}

.AapAddSummary-table {
    font-size: 12px;
}

.AapAddSummary-tableInput {
    width: 45px;
    font-size: 14px;
    /*border: solid 1px #cacaca;*/
    background-color: #dddddd;
}

.AapAddSummary-table-rowTitle {
    text-align: left;
    padding: 0 5px;
}

.AapAddSummary-table-removeColumn {
    width: 30px;
}
